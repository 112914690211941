function iniciarDatos(dataPago) {
    if (Data) {
      Data.init(dataPago);
    }
  }
  
  function reload(data) {
    if (Data) {
      Data.reload(data);
    }
  }

  